import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

interface Props {
  children: ReactNode;
  dark?: boolean;
  subtitle?: ReactNode;
}

export default function Layout({ children, dark, subtitle }: Props) {
  return (
    <>
      <GlobalStyle dark={dark} />
      <Header>
        <Title withSubtitle={!!subtitle}>
          <TitleLink dark={dark} to="/">
            Thomas Huston
          </TitleLink>
        </Title>
        {subtitle && <Subtitle dark={dark}>{subtitle}</Subtitle>}
      </Header>
      <Body>{children}</Body>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  body,
  html {
    background-color: #fff;
  }

  ${(props) =>
    props.dark &&
    css`
      body,
      html {
        background-color: #000;
      }
    `}
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 10px 50px 30px;
  }
`;

const Title = styled.h1<{ withSubtitle: boolean }>`
  margin: 0;

  ${({ withSubtitle }) =>
    withSubtitle &&
    css`
      margin-bottom: 12px;
    `}

  @media (min-width: 1024px) {
    flex: 0 0 250px;
    margin-bottom: 0;
  }
`;

const TitleLink = styled(Link) <{ dark: boolean }>`
  color: ${(props) => (props.dark ? '#fff' : '#000')};

  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const Subtitle = styled.h2`
  color: ${(props) => (props.dark ? '#fff' : '#000')};
  flex: 1;
  margin: 0;
  text-align: center;

  @media (min-width: 1024px) {
    padding: 0 250px 0 0;
  }
`;

const Body = styled.main`
  flex: 1;
`;
