import { Link, graphql, useStaticQuery } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import Layout from '../components/Layout';
import Masonry from '../components/Masonry';
import React from 'react';
import SEO from '../components/SEO';
import styled from 'styled-components';

export default function TravelPage() {
  const {
    trips,
  }: {
    trips: {
      edges: {
        node: {
          cover: {
            childImageSharp: {
              fluid: FluidObject;
            };
          };
          date: string;
          slug: string;
          title: string;
        };
      }[];
    };
  } = useStaticQuery(graphql`
    query Trips {
      trips: allTripsYaml {
        edges {
          node {
            cover {
              childImageSharp {
                fluid(quality: 95, maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date
            slug
            title
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Travel" />
      <Grid>
        {trips.edges.map(({ node: { cover, date, slug, title } }) => (
          <TripLink
            key={slug}
            to={`/travel/${slug}`}
            title={`${title}, ${date}`}
          >
            <Image
              fluid={cover.childImageSharp.fluid}
              alt={`${title}, ${date}`}
            />
            <GradientOverlay />
            <TripOverlay>
              <TripTitle>{title}</TripTitle>
              <TripDate>{date}</TripDate>
            </TripOverlay>
          </TripLink>
        ))}
      </Grid>
    </Layout>
  );
}

const Grid = styled.div`
  display: grid;
  grid-gap: 0;
  grid-auto-rows: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 2048px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const Image = styled(Img)`
  background-image: linear-gradient(to top, #f00, #00f);
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  &::after {
    background: #fff;
    content: ' ';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.15s ease-in;
    width: 100%;
  }
`;

const GradientOverlay = styled.div`
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0,
    transparent 50%,
    transparent
  );
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: box-shadow 0.15s ease-in;
  width: 100%;
`;

const TripLink = styled(Link)`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0;
  position: relative;

  &:hover,
  &:active {
    box-shadow: none;

    ${Image}::after {
      opacity: 0.2;
    }
  }
`;

const TripOverlay = styled.div`
  bottom: 50px;
  padding: 0 50px;
  position: absolute;
  width: 100%;
`;

const TripTitle = styled.h3`
  color: #fff;
  text-shadow: 0 1px 1px #000;
`;

const TripDate = styled.h4`
  color: #fff;
  margin-top: 0;
  text-shadow: 0 1px 1px #000;
`;
