import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import React from 'react';

type MetaProps = JSX.IntrinsicElements['meta'];

interface Props {
  description?: string;
  lang?: string;
  meta?: MetaProps[];
  keywords?: string[];
  title: string;
}

export default function SEO({
  description = '',
  lang = 'en',
  meta = [],
  keywords = [],
  title,
}: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const defaultMeta: MetaProps[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  const keywordsMeta: MetaProps[] =
    keywords.length > 0
      ? [{ name: 'keywords', content: keywords.join('. ') }]
      : [];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[...defaultMeta, ...keywordsMeta, ...meta]}
    />
  );
}
